<template>
  <v-form ref="form" v-if="transportEvent" v-model="valid">
    <v-subheader class="mt-3">{{
          $t("invoicing.transportEventTypes.receipt.receiptTripInfo")
    }}</v-subheader>
    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="transportEvent.trip.started"
          :label="$t('invoicing.fields.startTime')"
          :disabled="readOnly"
          :rules="[ requiredRule, dateTimeRule ]"
          :type="tripStartedTextFieldType"
          @dblclick="dblClickTripStartedTypeChange"
          @focus="$event.target.select()"
          placeholder="yyyy-MM-dd HH:mm"
          max="2099-12-31T00:00"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.trip.ended"
          :label="$t('invoicing.fields.endTime')"
          :disabled="readOnly"
          :rules="[ requiredRule, dateTimeRule ]"
          :type="tripEndedTextFieldType"
          @dblclick="dblClickTripEndedTypeChange"
          @focus="$event.target.select()"
          placeholder="yyyy-MM-dd HH:mm"
          max="2099-12-31T00:00"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.trip.startingPoint"
          label="Start address"
          :disabled="readOnly"
          :rules="[maxLengthRule(100)]"
          counter="100"
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.trip.destination"
          label="End address"
          :disabled="readOnly"
          :rules="[maxLengthRule(100)]"
          counter="100"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="transportEvent.trip.carNumber"
          :label="$t('invoicing.fields.carNumber')"
          :disabled="readOnly"
          :rules="[ requiredRule, maxLengthRule(8) ]"
          counter=8
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.paymentTerminalId"
          :label="$t('invoicing.fields.serviceNumber')"
          :disabled="readOnly"
          :rules="[ requiredRule, maxLengthRule(12) ]"
          counter="14"
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.trip.driverId"
          :label="$t('invoicing.fields.driverId')"
          :disabled="readOnly"
          :rules="[ maxLengthRule(10) ]"
          counter="14"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="transportEvent.orderId"
          :label="$t('invoicing.fields.orderId')"
          :disabled="readOnly"
          :rules="[ requiredRule, maxLengthRule(30) ]"
          counter=30
          require
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.trip.routeNumber"
          :label="$t('invoicing.fields.routeNumber')"
          :rules="[ maxLengthRule(20) ]"
          counter=20
        ></v-text-field>
        <v-text-field
          @change="updateFareDistanceValue"
          :value="transportEvent.fare.distance | formatDistanceToKm"
          :label="$t('invoicing.fields.distance')"
          :disabled="readOnly"
          :rules="[ requiredRule, maxLengthRule(6) ]"
          counter=6
          required
          suffix="km"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-subheader class="mt-3">{{
          $t("invoicing.transportEventTypes.receipt.receiptCharge")
    }}</v-subheader>
    <v-row>
      <v-col cols="8" md="4">
        <v-text-field
          v-model="transportEvent.fare.receiptNumber"
          :label="$t('invoicing.fields.receiptNumber')"
          :disabled="readOnly"
          :rules="[ digitRule, maxLengthRule(20)]"
          counter="20"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.taximeterSerialNumber"
          :label="$t('invoicing.fields.taximeterSerialNumber')"
          :disabled="readOnly"
          :rules="[ maxLengthRule(14) ]"
          counter="14"
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.cardNumber"
          :label="$t('invoicing.fields.cardNumber')"
          :disabled="readOnly"
          :rules="[maxLengthRule(20)]"
          counter="20">
        </v-text-field>
        <v-text-field
          v-if="transportEvent.kelaData.baseCost"
          v-model="transportEvent.kelaData.baseCost"
          :label="$t('invoicing.fields.baseCost')"
          :disabled="readOnly"
          @input="calculateSums"
          type="number"
          :rules="[ maxLengthWithoutDecimalSeparatorRule(6) ]"
          counter=7
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.paymentType"
          :label="$t('invoicing.fields.paymentType')"
          :disabled="true"
        ></v-text-field>
      </v-col>
        <v-col cols="8" md="4">
        <v-select
          v-model="transportEvent.fare.paymentMethod"
          :items="Object.values(paymentMethods)"
          :label="$t('invoicing.fields.paymentMethod')"
          :disabled="readOnly"
          :rules="[ requiredRule ]">
          <template v-slot:selection="{item, on}">
            {{ $t(paymentMethodToTranslationKey(item)) }}
            <v-icon v-on="on" style="margin-left: 5px">{{ item | paymentMethodToIcon }}</v-icon>
          </template>
          <template v-slot:item="{item, attrs, on}">
          <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
            <v-list-item-action>
              <v-icon v-on="on" style="margin-right: 5px">{{ item | paymentMethodToIcon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <v-row no-gutters align="center">
                  {{ $t(paymentMethodToTranslationKey(item)) }}
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-checkbox
                  :input-value="active"
              ></v-checkbox>
            </v-list-item-action>
          </v-list-item>
        </template>
        </v-select>
        <v-text-field
          v-if="transportEvent.fare.paymentMethod === paymentMethods.Invoice ||
                transportEvent.fare.paymentMethod === paymentMethods.App"
          v-model="transportEvent.customer.costCentreId"
          :label="$t('invoicing.fields.costCenter')"
          :disabled="readOnly"
          required
        ></v-text-field>
        <v-select
          v-model="transportEvent.fare.vatPercent"
          :items="[0.0, 10.0, 14.0, 24.0, 25.5]"
          :rules="[ requiredRule ]"
          @input="calculateSums"
          :label="$t('invoicing.fields.vatPercent')"
          :disabled="readOnly"
          required>
          <template slot="selection" slot-scope="{ item }">
              {{ item }} %
          </template>
          <template slot="item" slot-scope="{ item }">
              {{ item }} %
          </template>
        </v-select>
        <v-text-field
        :label="$t('invoicing.fields.cost')"
          @change="updateFareCostValue"
          :value="transportEvent.fare.cost | formatPrice"
          :disabled="readOnly"
          type="number"
          :rules="[ requiredRule, decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
          counter=9
          suffix="€"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="8" md="4">
        <v-text-field
          @change="updateFareDeductibleValue"
          :value="transportEvent.fare.deductible | formatPrice"
          :label="$t('invoicing.fields.deductible')"
          :disabled="readOnly"
          @input="calculateSums"
          type="number"
          :rules="[ requiredRule, decimalRule, maxLengthWithoutDecimalSeparatorRule(6) ]"
          counter=7
          suffix="€"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.reimbursement"
          :label="$t('invoicing.fields.reimbursement')"
          disabled
          type="number"
          :rules="[ requiredRule, decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
          counter=9
          suffix="€"
          required
        ></v-text-field>
        <v-text-field
          @change="updateFareDiscountValue"
          :value="transportEvent.fare.discountAmount | formatPrice"
          :label="$t('invoicing.fields.discount')"
          :disabled="readOnly"
          type="number"
          :rules="[ decimalRule, maxLengthWithoutDecimalSeparatorRule(8) ]"
          counter=9
          suffix="€"
        ></v-text-field>
        <v-text-field
          v-if="transportEvent.kelaData.routePrice"
          v-model="transportEvent.kelaData.routePrice"
          :label="$t('invoicing.fields.routeTotal')"
          @input="calculateSums"
          type="number"
          :rules="[ maxLengthWithoutDecimalSeparatorRule(6) ]"
          counter=7
          suffix="€"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="auto">
        <v-subheader>{{ $t("invoicing.fares") }}</v-subheader>
        <FareChipGroup
          ref="fareChipGroup"
          :fares="transportEvent.fare.tariffs"
          :allowedFareTypes="allowedFareTypes"
          :maxAmount="3"
          :readOnly="readOnly"
          @update="updateFares"
          :verbose=true
        ></FareChipGroup>
      </v-col>
      <v-col cols="12" sm="auto">
        <v-subheader>{{ $t("invoicing.extraCharges") }}</v-subheader>
        <FeeChipGroup
          ref="feeChipGroup"
          :fees="transportEvent.fare.extras"
          :allowedFeeTypes="allowedFeeTypes"
          :maxAmount="5"
          :readOnly="readOnly"
          @update="updateFees"
        ></FeeChipGroup>
      </v-col>
      <v-col cols="12" sm="auto">
        <v-subheader>{{ $t("invoicing.waitFees") }}</v-subheader>
        <WaitFeeChipGroup
          ref="waitFeeChipGroup"
          :waitFees="transportEvent.fare.waitFees"
          :allowedWaitFeeTypes="allowedWaitFeeTypes"
          :maxAmount="2"
          :readOnly="readOnly"
          @update="updateWaitFees"
        ></WaitFeeChipGroup>
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-textarea
          v-model="transportEvent.comments"
          :label="$t('invoicing.fields.comments')"
          outlined
          rows=3
          class="mx-2"
      ></v-textarea>
    </v-row>
    <v-row>
      <v-textarea
        v-if="transportEvent.fare.paymentMethod === paymentMethods.Invoice && transportEvent.customer.costCentreId"
        v-model="transportEvent.salesInvoiceMessage"
        :label="$t('invoicing.fields.salesInvoiceMessage')"
        :disabled="readOnly"
        outlined
        rows=3
        class="mx-2"
      ></v-textarea>
    </v-row>
    <v-row>
      <v-textarea
        v-model="transportEvent.purchaseInvoiceMessage"
        :label="$t('invoicing.fields.purchaseInvoiceMessage')"
        :disabled="readOnly"
        outlined
        rows=3
        class="mx-2"
      ></v-textarea>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          color="primary"
          :loading="commentUpdating"
          @click="updateComment">
          {{$t("invoicing.updateComments")}}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="needsRehandling && hasSocialServiceOrTaxiCard">
      <v-col cols="8" md="4">
          <v-subheader class="mt-3">{{
          $t("invoicing.transportEventTypes.receipt.cardRehandleTitle")
        }}</v-subheader>
        <v-btn depressed color="primary" @click="checkCardValidity(transportEvent.fare.cardNumber, transportEvent.fare.paymentTerminalId)">
          Tarkista kortti
        </v-btn>
        </v-col>
        <v-col cols="8" md="4" v-if="cardValidityResponse">
          <v-text-field
              v-model="cardValidityResponse.cardNumber"
              label="Card number"
              readonly
              class="mx-2"
              :append-icon="cardValidityResponse.isValid ? 'mdi-check' : 'mdi-close'"
          ></v-text-field>
          <v-switch
            v-model="authorize"
            label="Tee kortinvarmennus"
          >
          </v-switch>
            <v-radio-group v-if="cardValidityResponse.taxiCardAttributes"
                v-model="selectedCardAttribute"
                class="mx-2">
              <v-radio
                v-for="(attribute, index) in cardValidityResponse.taxiCardAttributes"
                :key="index"
                :label="attribute.name + '(Tuotenumero: ' + attribute.externalId + ')'"
                :value="attribute.externalId"
                class="mx-2"
            ></v-radio>
            </v-radio-group>
            <v-btn depressed color="warning" @click="rehandleTaxicardPayment(transportEvent.id, selectedCardAttribute, authorize)">
              Uudelleenkäsittele korttitapahtuma
            </v-btn>
      </v-col>
    </v-row>
    <v-row  v-if="needsRehandling && hasVerifoneCardPayment">
      <v-col cols="auto">
        <v-subheader class="mt-1">{{
        $t("invoicing.transportEventTypes.receipt.verifoneEventDetailsTitle")
      }}</v-subheader>
        <v-icon v-if="this.verifoneDetailsLoading" class="ml-2" color="primary" size="24">mdi-loading mdi-spin</v-icon>
        <v-textarea v-if="!this.verifoneDetailsLoading && this.verifoneDetails"
          outlined
          readonly
          name="duplicateVerifoneEvents"
          label="Info"
          :value=this.textForVerifoneTransactionDetails()
        ></v-textarea>
        <v-btn v-if="this.verifoneDetails && this.verifoneDetails.hasDuplicates" depressed color="primary" @click="this.showDuplicateVerifoneEvents">
          Katso duplikaattitapahtumat
        </v-btn>
        <v-btn
          v-if="showSetToBePurchaseInvoiced"
          @click="handleDuplicateVerifoneEvents"
          :loading="sendingSetToBePurchaseInvoiced"
          color="info"
          class="ml-4">
          {{$t("invoicing.setToBePurchaseInvoiced")}}
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-form>
</template>

<script>
import { RepositoryFactory } from "../../data/repositories/repositoryFactory";
import FeeChipGroup from "./FeeChipGroups/FeeChipGroup";
import FareChipGroup from "./FeeChipGroups/FareChipGroup";
import WaitFeeChipGroup from "./FeeChipGroups/WaitFeeChipGroup";
import { ValidationStatusType, TransportEventStatusMixin } from "../../models/TransportEventStatusTypes";
import InputRuleMixin from "../Inputs/InputRuleMixin";
import _ from "lodash";
import { TransportEventTypeMixin } from "../../models/TransportEventTypes";
import { TransportEventInvoicingMixin } from "../../models/TransportEventInvoicingStates";
import { FeeTypeMixin, FeeTypeMitax } from "../../models/FeeTypes";
import { FareTypeMixin, FareTypeMitax } from "../../models/FareTypes";
import { PaymentMethodMixin } from "../../models/PaymentMethods";
import { municipalityOIDs } from "../../models/Municipalities";

const GeoRepository = RepositoryFactory.get("geo");

const mapToGeoCodeRequest = (addressString) => {
    const parts = addressString.split(",");
    if (parts.length < 2) {
        return null;
    }
    var address = parts.find((p) => /\d/.test(p) && /[a-zA-Z]/.test(p));
    if (address == null || address.indexOf(" ") < 0) {
        return null;
    }
    var zipcode = parts.find((p) => /\d/.test(p) && !/[a-zA-Z]/.test(p));
    var city = parts
        .slice()
        .reverse()
        .find((p) => !/\d/.test(p) && /[a-zA-Z]/.test(p));
    var street = address.split(" ");
    var streetName = street.find((p) => !/\d/.test(p) && /[a-zA-Z]/.test(p));
    var streetNumber = street.find((p) => /\d/.test(p) && !/[a-zA-Z]/.test(p));
    return {
        Address: {
            StreetName: streetName,
            StreetNumber: streetNumber,
            City: city,
            Zipcode: zipcode,
            Country: "Finland"
        }
    };
};

async function getCoordinates(waypoint) {
    if (
        waypoint == null ||
    waypoint.address == null ||
    waypoint.address.length < 3
    ) {
        return "virheellinen osoite";
    }
    var request = mapToGeoCodeRequest(waypoint.address);
    if (request == null) {
        return "virheellinen osoite";
    }
    return GeoRepository.geoCodeAddress(request, "").then((response) => {
        if (response == null || response.status !== 200) {
            return "osoitetta ei löytynyt";
        }
        return response.data.point.latitude + ", " + response.data.point.longitude;
    });
}

export default {
    components: { FeeChipGroup, FareChipGroup, WaitFeeChipGroup },
    mixins: [
        InputRuleMixin, FeeTypeMixin, FareTypeMixin,
        PaymentMethodMixin, TransportEventStatusMixin, TransportEventTypeMixin, TransportEventInvoicingMixin
    ],
    props: {
        originalTransportEvent: Object,
        isNew: Boolean,
        readOnly: Boolean
    },
    data() {
        return {
            transportEvent: _.cloneDeep(this.originalTransportEvent),
            waypoints: {
                startingPoint: { address: "", municipalityCode: "", coordinates: "" },
                destination: { address: "", municipalityCode: "", coordinates: "" }
            },
            valid: true,
            tabs: [
                this.$t("invoicing.transportEventTypes.receipt.receiptCharge"),
                this.$t("invoicing.transportEventTypes.receipt.receiptTripInfo"),
                this.$t("invoicing.transportEventTypes.receipt.receiptComments"),
                this.$t("invoicing.transportEventTypes.receipt.cardTransactionInformation")
            ],
            tab: 0,
            decisionIds: ["VPL", "SHL"],
            tripTypes: [
                { value: "J", text: "Julkinen" },
                { value: "Y", text: "Yksityinen" },
                { value: "K", text: "Kelan kuntoutus" },
                { value: "O", text: "Ostopalvelu" },
                { value: "S", text: "Opisk.th" }
            ],
            ordererTypes: [
                { value: "T", text: "Terveydenhuolto" },
                { value: "U", text: "Tuttu taksi" },
                { value: "M", text: "Muu" },
                { value: "V", text: "Verkko terveydenhuolto" },
                { value: "X", text: "Verkko tuttu taksi" },
                { value: "Z", text: "Verkko muu" }
            ],
            carTypes: [
                { value: 1, text: "Taksi" },
                { value: 4, text: "Invataksi" },
                { value: 6, text: "Paaritaksi" },
                { value: 7, text: "Henkilöliikenneluvallinen auto" },
                { value: 9, text: "Esteetön ajoneuvo (pieni)" }
            ],
            carTypeRequirements: [
                { value: 1, text: "Taksi" },
                { value: 4, text: "Invataksi" },
                { value: 6, text: "Paaritaksi" }
            ],
            carPoolValues: [
                { value: null, text: "Ei" },
                { value: "K", text: "Kyllä" }
            ],
            tripChainValues: [
                { value: null, text: "-" },
                { value: "K", text: "K" },
                { value: "S", text: "S" }
            ],
            tripTopicValues: [
                { value: "S", text: "Sairaus" },
                { value: "L", text: "Liikenneolosuhteet" }
            ],
            deductibleCollectedValues: [
                { value: "", text: "Ei" },
                { value: "K", text: "Kyllä" }
            ],
            municipalities: municipalityOIDs,
            allowedFareTypes: [
                FareTypeMitax.Single,
                FareTypeMitax.Double,
                FareTypeMitax.Fixed,
                FareTypeMitax.Wait
            ],
            allowedMitaxFeeTypes: [
                FeeTypeMitax.T,
                FeeTypeMitax.E,
                FeeTypeMitax.A,
                FeeTypeMitax.P,
                FeeTypeMitax.K,
                FeeTypeMitax.L,
                FeeTypeMitax.M
            ],
            allowedWaitFeeTypes: ["O"],
            toNotBeValidated: false,
            commentUpdating: false,
            tripEndedTextFieldType: "datetime-local",
            tripStartedTextFieldType: "datetime-local",
            selectedCardAttribute: null,
            cardValidity: null,
            authorize: true,
            snackbar: false,
            snackbarText: "",
            snackbarColor: "",
            verifoneDetailsLoading: false,
            verifoneDetails: null,
            sendingSetToBePurchaseInvoiced: false,
            verifoneSetToBePurchaseInvoiced: false
        };
    },
    mounted() {
        this.verifoneTransportEventDetails();
    },
    computed: {
        allowedFeeTypes() {
            return this.allowedMitaxFeeTypes
                .map((mitaxFeeType) => this.mitaxFeeTypeToFeeType(mitaxFeeType))
                .flat();
        },
        deductibleNotCollected() {
            return !this.transportEvent.fare.deductibleCollected === "K";
        },
        carPool() {
            return this.transportEvent.trip.carPool;
        },
        paymentMethodText() {
            return this.$t(this.paymentMethodToTranslationKey(this.transportEvent.fare.paymentMethod));
        },
        needsRehandling() {
            if (this.hasSocialServiceOrTaxiCard || this.hasVerifoneCardPayment) {
                if (this.transportEvent.validationStatus === 1 &&
            this.transportEvent.salesInvoiceStatus === 1 &&
            this.transportEvent.purchaseInvoiceStatus === 1) {
                    return true;
                } else if (this.transportEvent.validationStatus === 2 &&
            this.transportEvent.salesInvoiceStatus === 1 &&
            this.transportEvent.purchaseInvoiceStatus === 1) {
                    return true;
                } else if (this.transportEvent.validationStatus === 6 &&
            this.transportEvent.salesInvoiceStatus === 2 &&
            this.transportEvent.purchaseInvoiceStatus === 2) {
                    return true;
                }
            }

            return false;
        },
        cardValidityResponse() {
            return this.cardValidity;
        },
        hasSocialServiceOrTaxiCard() {
            if (this.transportEvent != null && this.transportEvent.fare != null &&
            this.transportEvent.fare.cardNumber != null &&
                this.transportEvent.fare.cardNumber.length > 0 &&
                (this.transportEvent.fare.paymentMethod === this.paymentMethods.SocialServiceCard ||
                  this.transportEvent.fare.paymentMethod === this.paymentMethods.TaxiCard)) {
                return true;
            }

            return false;
        },
        hasVerifoneCardPayment() {
            if (this.transportEvent != null && this.transportEvent.fare != null &&
                (this.transportEvent.fare.paymentMethod === this.paymentMethods.CreditCard ||
                this.transportEvent.fare.paymentMethod === this.paymentMethods.DebitCard)) {
                return true;
            }

            return false;
        },
        showSetToBePurchaseInvoiced() {
            if (this.$store.state.user.tenant === "ltx" &&
        (this.transportEvent.purchaseInvoiceStatus === this.purchaseInvoiceStates.Unprocessed || this.transportEvent.purchaseInvoiceStatus === this.purchaseInvoiceStates.Waiting) &&
        (this.transportEvent.salesInvoiceStatus === this.salesInvoiceStates.Unprocessed || this.transportEvent.salesInvoiceStatus === this.salesInvoiceStates.NotToBeInvoiced) &&
        this.transportEvent.eventType === this.transportEventType.Receipt &&
        (this.transportEvent.fare.paymentMethod === this.paymentMethods.CreditCard || this.transportEvent.fare.paymentMethod === this.paymentMethods.DebitCard)) {
                return true;
            }
            return false;
        }
    },
    watch: {
        originalTransportEvent(val) {
            this.toNotBeValidated = false;
            this.transportEvent = !val ? {} : _.cloneDeep(val);
            if (!this.transportEvent.fare || !this.transportEvent.fare.extras) {
                this.transportEvent = {
                    fare: {
                        extras: []
                    }
                };
            }

            let pick = null;
            let drop = null;
            if (this.transportEvent.trip.waypoints) {
                pick = this.transportEvent.trip.waypoints.find((wp) => wp.type === 1);
                drop = this.transportEvent.trip.waypoints.find((wp) => wp.type === 2);
            } else {
                this.transportEvent.trip.waypoints = [];
            }

            this.waypoints = {
                startingPoint: {
                    address: pick
                        ? pick.location.address.streetName
                        : this.transportEvent.trip.startingPoint,
                    municipalityCode: pick
                        ? pick.location.address.additionalDetails.Kela_KuntaKoodi
                        : "",
                    coordinates: pick
                        ? pick.location.point.latitude +
              ", " +
              pick.location.point.longitude
                        : ""
                },
                destination: {
                    address: drop
                        ? drop.location.address.streetName
                        : this.transportEvent.trip.destination,
                    municipalityCode: drop
                        ? drop.location.address.additionalDetails.Kela_KuntaKoodi
                        : "",
                    coordinates: drop
                        ? drop.location.point.latitude +
              ", " +
              drop.location.point.longitude
                        : ""
                }
            };

            this.transportEvent.fare.vatPercent =
        this.transportEvent.fare.vatPercent;
            this.transportEvent.validationStatus =
        ValidationStatusType.ReadyForValidation;
            if (!this.transportEvent.fare.distance) {
                this.transportEvent.fare.distance = 0;
            }
        },
        toNotBeValidated(val) {
            if (val) {
                this.transportEvent.validationStatus =
          this.originalTransportEvent.validationStatus;
            } else {
                this.transportEvent.validationStatus =
          ValidationStatusType.ReadyForValidation;
            }
        },
        carPool(val) {
            if (val !== "K") {
                this.transportEvent.fare.cost = this.transportEvent.kelaData.routePrice;
            }
        },
        deductibleNotCollected(val) {
            this.transportEvent.fare.deductibleCollected = val ? " " : "K";
        }
    },
    created() {
        let pick = null;
        let drop = null;
        if (this.transportEvent.trip.waypoints) {
            pick = this.transportEvent.trip.waypoints.find((wp) => wp.type === 1);
            drop = this.transportEvent.trip.waypoints.find((wp) => wp.type === 2);
        } else {
            this.transportEvent.trip.waypoints = [];
        }

        this.waypoints = {
            startingPoint: {
                address: pick
                    ? pick.location.address.streetName
                    : this.transportEvent.trip.startingPoint,
                coordinates: pick
                    ? pick.location.point.latitude + ", " + pick.location.point.longitude
                    : ""
            },
            destination: {
                address: drop
                    ? drop.location.address.streetName
                    : this.transportEvent.trip.destination,
                coordinates: drop
                    ? drop.location.point.latitude + ", " + drop.location.point.longitude
                    : ""
            }
        };
    },
    methods: {
        validate() {
            let isValid = this.$refs.form.validate();
            this.updateWaypoints();
            if (!isValid) {
                let errors = [];
                this.$refs.form.inputs.forEach((e) => {
                    if (e.errorBucket && e.errorBucket.length) {
                        errors.push(e.label + ": " + e.errorBucket);
                    }
                });
                this.$emit("validationFailed", errors);
            }
            return this.$refs.form.validate();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        updateFees(fees) {
            this.transportEvent.fare.extras = fees;
            this.$refs.feeChipGroup.closeFeeDialog();
        },
        updateFares(fares) {
            this.transportEvent.fare.tariffs = fares;
            this.$refs.fareChipGroup.closeFareDialog();
        },
        updateWaitFees(waitFees) {
            this.transportEvent.fare.waitFees = waitFees;
            this.$refs.waitFeeChipGroup.closeWaitFeeDialog();
        },
        calculateSums() {
            let fare = this.transportEvent.fare;
            fare.reimbursement = (fare.cost - fare.deductible).toFixed(2);
            fare.reimbursementVat = this.calculateVat(fare.reimbursement, fare.vatPercent);
            fare.deductibleVat = this.calculateVat(fare.deductible, fare.vatPercent);
        },
        calculateVat(sumIncludingVat, vatPercent) {
            let vat = sumIncludingVat - sumIncludingVat / (vatPercent / 100.0 + 1);
            return vat.toFixed(2);
        },
        updateFareCostValue(updateFareCostEvent) {
            this.transportEvent.fare.cost = updateFareCostEvent;
            this.calculateSums();
        },
        updateFareDistanceValue(updateFareDistanceEvent) {
            if (updateFareDistanceEvent.indexOf(",") > -1 || updateFareDistanceEvent.indexOf(".") > -1) {
                updateFareDistanceEvent = Number(updateFareDistanceEvent.replace(",", "."));
            } else {
                updateFareDistanceEvent = Number(updateFareDistanceEvent);
            }
            this.transportEvent.fare.distance = Math.round(updateFareDistanceEvent * 1000);
        },
        updateFareDeductibleValue(updateFareDeductibleEvent) {
            this.transportEvent.fare.deductible = updateFareDeductibleEvent;
            this.calculateSums();
        },
        updateFareDiscountValue(updateFareDiscountEvent) {
            this.transportEvent.fare.discountAmount = updateFareDiscountEvent;
        },
        async updateStartingPointCoordinates() {
            this.waypoints.startingPoint.coordinates = await getCoordinates(
                this.waypoints.startingPoint
            );
        },
        async updateDestinationCoordinates() {
            this.waypoints.destination.coordinates = await getCoordinates(
                this.waypoints.destination
            );
        },
        updateWaypoints() {
            let pickIndex = this.transportEvent.trip.waypoints.findIndex(
                (wp) => wp.type === 1
            );
            let startingPointLat = "";
            let startingPointLong = "";
            if (this.waypoints.startingPoint.coordinates.includes(",")) {
                startingPointLat = this.waypoints.startingPoint.coordinates
                    .split(",")[0]
                    .trim();
                startingPointLong = this.waypoints.startingPoint.coordinates
                    .split(",")[1]
                    .trim();
                if (pickIndex >= 0) {
                    this.transportEvent.trip.waypoints[pickIndex].location.address.streetName =
          this.waypoints.startingPoint.address;
                    this.transportEvent.trip.waypoints[pickIndex].location.address.additionalDetails.Kela_KuntaKoodi =
          this.waypoints.startingPoint.municipalityCode;
                    this.transportEvent.trip.waypoints[pickIndex].location.point.latitude =
          startingPointLat;
                    this.transportEvent.trip.waypoints[pickIndex].location.point.longitude =
          startingPointLong;
                } else {
                    this.transportEvent.trip.waypoints.push({
                        type: 1,
                        location: {
                            address: {
                                streetName: this.waypoints.startingPoint.address,
                                additionalDetails: {
                                    Kela_KuntaKoodi: this.waypoints.startingPoint.municipalityCode
                                }
                            },
                            point: {
                                latitude: startingPointLat,
                                longitude: startingPointLong
                            }
                        }
                    });
                }
                let dropIndex = this.transportEvent.trip.waypoints.findIndex(
                    (wp) => wp.type === 2
                );
                let destinationLat = "";
                let destinationLong = "";
                if (this.waypoints.destination.coordinates.includes(",")) {
                    destinationLat = this.waypoints.destination.coordinates
                        .split(",")[0]
                        .trim();
                    destinationLong = this.waypoints.destination.coordinates
                        .split(",")[1]
                        .trim();
                }

                if (dropIndex >= 0) {
                    this.transportEvent.trip.waypoints[dropIndex].location.address.streetName =
          this.waypoints.destination.address;
                    this.transportEvent.trip.waypoints[dropIndex].location.address.additionalDetails.Kela_KuntaKoodi =
          this.waypoints.destination.municipalityCode;
                    this.transportEvent.trip.waypoints[dropIndex].location.point.latitude =
          destinationLat;
                    this.transportEvent.trip.waypoints[dropIndex].location.point.longitude =
          destinationLong;
                } else {
                    this.transportEvent.trip.waypoints.push({
                        type: 2,
                        location: {
                            address: {
                                streetName: this.waypoints.destination.address,
                                additionalDetails: {
                                    Kela_KuntaKoodi: this.waypoints.destination.municipalityCode
                                }
                            },
                            point: {
                                latitude: destinationLat,
                                longitude: destinationLong
                            }
                        }
                    });
                }
            }
        },
        updateComment() {
            this.commentUpdating = true;
            this.$store.dispatch("transportEventTable/updateTransportEventComments", {
                id: this.transportEvent.id,
                comments: {
                    comments: this.transportEvent.comments,
                    salesInvoiceMessage: this.transportEvent.salesInvoiceMessage,
                    purchaseInvoiceMessage: this.transportEvent.purchaseInvoiceMessage
                }
            })
                .finally(() => {
                    this.commentUpdating = false;
                });
        },
        dblClickTripEndedTypeChange(e) {
            if (this.tripEndedTextFieldType === "datetime-local") {
                this.tripEndedTextFieldType = "text";
            } else {
                this.tripEndedTextFieldType = "datetime-local";
            }
        },
        dblClickTripStartedTypeChange(e) {
            if (this.tripStartedTextFieldType === "datetime-local") {
                this.tripStartedTextFieldType = "text";
            } else {
                this.tripStartedTextFieldType = "datetime-local";
            }
        },
        checkCardValidity(cardNumber, serviceNumber) {
            RepositoryFactory.get("invoicing").getCardValidity(cardNumber, serviceNumber)
                .then(response => {
                    this.cardValidity = response.data.item;
                })
                .catch(error => {
                    this.snackbarText = error;
                    this.snackbarColor = "error";
                    this.snackbar = true;
                });
        },
        rehandleTaxicardPayment(id, externalId, authorize) {
            if (!window.confirm("Oletko varma, että haluat käsitellä tapahtuman uudelleen? Tapahtuma ilmestyy Semel IP hetken kulttua.")) {
                return;
            }
            RepositoryFactory.get("invoicing").rehandleTaxicardPaymentToSemelCF(id, externalId, authorize)
                .then(response => {
                    this.snackbarText = "Uudelleenkäsittely onnistui";
                    this.snackbarColor = "success";
                    this.snackbar = true;
                })
                .catch(error => {
                    this.snackbarText = error;
                    this.snackbarColor = "error";
                    this.snackbar = true;
                });
        },
        verifoneTransportEventDetails() {
            if (this.transportEvent != null && this.transportEvent.fare != null &&
          (this.transportEvent.fare.paymentMethod === this.paymentMethods.DebitCard ||
          this.transportEvent.fare.paymentMethod === this.paymentMethods.CreditCard)) {
                this.verifoneDetailsLoading = true;
                RepositoryFactory.get("invoicing").getVerifoneTransportEventDetails(this.transportEvent.id)
                    .then(response => {
                        this.verifoneDetails = response.data;
                        this.verifoneDetailsLoading = false;
                    })
                    .catch(error => {
                        this.snackbarText = error;
                        this.snackbarColor = "error";
                        this.snackbar = true;
                        this.verifoneDetailsLoading = false;
                    });
            }
        },
        showDuplicateVerifoneEvents() {
            this.$store.dispatch("transportEventTable/getDuplicateVerifoneTransportEvents", this.transportEvent.id)
                .then(() => {
                    this.$router.push({ name: "Invoicing" });
                    this.$emit("close-dialog");
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                });
        },
        handleDuplicateVerifoneEvents() {
            this.sendingSetToBePurchaseInvoiced = true;
            RepositoryFactory.get("invoicing").setVerifoneTransportEventToPurchaseInvoicing(this.transportEvent.id)
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.showDuplicateVerifoneEvents();
                    this.sendingSetToBePurchaseInvoiced = false;
                });
        },
        textForVerifoneTransactionDetails() {
            if (this.verifoneDetails.hasDuplicates) {
                let textToReturn = "Maksutapahtumalla on duplikaatteja. ";

                if (this.verifoneDetails.handled) {
                    return textToReturn + "Tapahtuma on jo käsitelty";
                }

                textToReturn += this.verifoneDetails.canBeHandled ? "Voidaan tilittää. "
                    : "Ei voida tilittää - Tapahtuman duplikaatti on jo tilitetty. ";
                return textToReturn;
            }
            return "Maksutapahtumaa ei löytynyt";
        }
    }
};
</script>

<style>
</style>
