var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "800" },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      _vm.isNewFee ? "invoicing.addNewFee" : "invoicing.editFee"
                    )
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.allowedFeeTypes,
                                  rules: [
                                    function(v) {
                                      return !!v || "Fee type is required"
                                    }
                                  ],
                                  label: this.$t("invoicing.feeType"),
                                  required: ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm.useLikumaTexts
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "feeTypeToLikumaText"
                                                    )(item)
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm.useKelaTexts
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("feeTypeToKelaText")(
                                                      item
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("feeTypeToText")(
                                                      item
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm.useLikumaTexts
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "feeTypeToLikumaText"
                                                    )(item)
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm.useKelaTexts
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("feeTypeToKelaText")(
                                                      item
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("feeTypeToText")(
                                                      item
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.selectedFeeType,
                                  callback: function($$v) {
                                    _vm.selectedFeeType = $$v
                                  },
                                  expression: "selectedFeeType"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: this.$t("invoicing.price"),
                                  type: "number",
                                  rules: [
                                    function(v) {
                                      return !!v || "Price cannot be empty"
                                    },
                                    function(v) {
                                      return (
                                        /^\d+((.|,)\d+)?$/.test(v) ||
                                        "Price is not valid decimal value"
                                      )
                                    },
                                    function(v) {
                                      return (
                                        !v ||
                                        v
                                          .toString()
                                          .replace(".", "")
                                          .replace(",", "").length <= 8 ||
                                        "Price cannot be longer than 8 characters without decimal point"
                                      )
                                    }
                                  ],
                                  counter: "9",
                                  required: ""
                                },
                                model: {
                                  value: _vm.fee.price,
                                  callback: function($$v) {
                                    _vm.$set(_vm.fee, "price", $$v)
                                  },
                                  expression: "fee.price"
                                }
                              })
                            ],
                            1
                          ),
                          !_vm.useKelaTexts
                            ? _c(
                                "v-col",
                                { staticClass: "pl-0" },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: this.$t("invoicing.vat"),
                                      items: [0.0, 10.0, 14.0, 24.0, 25.5],
                                      rules: [_vm.requiredRule],
                                      suffix: "%"
                                    },
                                    model: {
                                      value: _vm.fee.vat,
                                      callback: function($$v) {
                                        _vm.$set(_vm.fee, "vat", $$v)
                                      },
                                      expression: "fee.vat"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.useKelaTexts && !_vm.useLikumaTexts
                            ? _c(
                                "v-col",
                                { staticClass: "pl-0" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: this.$t("invoicing.feeName"),
                                      disabled: true
                                    },
                                    model: {
                                      value: _vm.fee.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.fee, "name", $$v)
                                      },
                                      expression: "fee.name"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-4" },
            [
              _c("v-spacer"),
              _vm.isNewFee
                ? _c(
                    "v-btn",
                    {
                      attrs: { disabled: !_vm.valid, color: "success" },
                      on: { click: _vm.createFee }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("invoicing.addFee")) + " ")]
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: { disabled: !_vm.valid, color: "primary" },
                      on: { click: _vm.saveFee }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common.saveChanges")) + " ")]
                  ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.close")) + " ")]
              ),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }