var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "800" },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.isNewPrice ? "Add new price" : "Edit price") +
                  " "
              )
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: [
                                function(v) {
                                  return !!v || "Name is required"
                                },
                                function(v) {
                                  return (
                                    !v ||
                                    v.length <= 100 ||
                                    "Name can not be longer than 100 characters"
                                  )
                                }
                              ],
                              counter: "100",
                              label: "Name"
                            },
                            model: {
                              value: _vm.price.unitPriceInfo.name,
                              callback: function($$v) {
                                _vm.$set(_vm.price.unitPriceInfo, "name", $$v)
                              },
                              expression: "price.unitPriceInfo.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: [
                                    function(v) {
                                      return !!v || "Vendor price is required"
                                    }
                                  ],
                                  label: "Vendor price",
                                  type: "number",
                                  required: ""
                                },
                                model: {
                                  value:
                                    _vm.price.unitPriceInfo.vendorUnitPrice,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.price.unitPriceInfo,
                                      "vendorUnitPrice",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "price.unitPriceInfo.vendorUnitPrice"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: [
                                    function(v) {
                                      return !!v || "Customer price is required"
                                    }
                                  ],
                                  label: "Customer price",
                                  type: "number",
                                  required: ""
                                },
                                model: {
                                  value:
                                    _vm.price.unitPriceInfo.customerUnitPrice,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.price.unitPriceInfo,
                                      "customerUnitPrice",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "price.unitPriceInfo.customerUnitPrice"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "px-0" },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: [0.0, 10.0, 14.0, 24.0, 25.5],
                                  rules: [
                                    function(v) {
                                      return !!v || "VAT is required"
                                    }
                                  ],
                                  label: "VAT",
                                  required: ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(" " + _vm._s(item) + " % ")
                                      ]
                                    }
                                  },
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(" " + _vm._s(item) + " % ")
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.price.unitPriceInfo.vatPercent,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.price.unitPriceInfo,
                                      "vatPercent",
                                      $$v
                                    )
                                  },
                                  expression: "price.unitPriceInfo.vatPercent"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-row", [_c("v-subheader", [_vm._v("Rules")])], 1),
                      _c(
                        "v-row",
                        [
                          _c("v-switch", {
                            attrs: { label: "Is default price" },
                            model: {
                              value: _vm.isDefault,
                              callback: function($$v) {
                                _vm.isDefault = $$v
                              },
                              expression: "isDefault"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-slide-y-transition",
                        [
                          !_vm.isDefault
                            ? _c("RuleChipGroup", {
                                ref: "ruleChipGroup",
                                attrs: {
                                  rules: _vm.price.rules.filter(function(r) {
                                    return r.ruleType !== "Default"
                                  }),
                                  allowedRuleTypes: _vm.allowedRuleTypes
                                },
                                on: { update: _vm.updateRules }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-4" },
            [
              _c("v-spacer"),
              _vm.isNewPrice
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.valid,
                        loading: _vm.isUpdating,
                        color: "success"
                      },
                      on: { click: _vm.createPrice }
                    },
                    [_vm._v(" Add price ")]
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: { disabled: !_vm.valid, color: "primary" },
                      on: { click: _vm.savePrice }
                    },
                    [_vm._v(" Save changes ")]
                  ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }