<template>
  <v-form ref="form" v-if="transportEvent" v-model="valid">
    <v-tabs v-model="tab">
      <v-tab v-for="(item, i) in tabs" :key="i">
        <div v-if="(i !== 1) || (i === 1 && needsRehandling)">
          {{ item }}
        </div>
      </v-tab>
      <v-tab-item key="0">
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="transportEvent.orderId"
          label="Order Id"
          :disabled="readOnly || !isNew"
          :rules="[requiredRule, digitRule, maxLengthRule(21)]"
          counter="21"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.trip.started"
          label="Trip start timestamp"
          :disabled="readOnly"
          :rules="[requiredRule, dateTimeRule]"
          :type="tripStartedTextFieldType"
          @dblclick="dblClickTripStartedTypeChange"
          @focus="$event.target.select()"
          placeholder="yyyy-MM-dd HH:mm"
          max="2099-12-31T00:00"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.trip.ended"
          label="Trip end timestamp"
          :disabled="readOnly"
          :rules="[requiredRule, dateTimeRule]"
          :type="tripEndedTextFieldType"
          @dblclick="dblClickTripEndedTypeChange"
          @focus="$event.target.select()"
          placeholder="yyyy-MM-dd HH:mm"
          max="2099-12-31T00:00"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.duration"
          label="Trip time"
          :disabled="readOnly"
          :rules="[requiredRule, digitRule, maxLengthRule(3)]"
          counter="3"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.trip.carNumber"
          label="Vehicle id"
          :disabled="readOnly"
          :rules="[requiredRule, maxLengthRule(8)]"
          counter="8"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.taximeterSerialNumber"
          label="Meter id"
          :disabled="readOnly"
          :rules="[requiredRule, maxLengthRule(14)]"
          counter="14"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="transportEvent.fare.distance"
          label="Trip distance (m)"
          :disabled="readOnly"
          :rules="[requiredRule, digitRule, maxLengthRule(6)]"
          counter="6"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.waitingTime"
          label="Waiting time"
          :disabled="readOnly"
          :rules="[requiredRule, digitRule, maxLengthRule(4)]"
          counter="4"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.paymentTerminalId"
          label="Service number"
          :disabled="readOnly"
          :rules="[requiredRule, maxLengthRule(12)]"
          counter="14"
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.trip.driverId"
          label="Driver id"
          :disabled="readOnly"
          :rules="[maxLengthRule(12)]"
          counter="12"
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.trip.startingPoint"
          label="Start address"
          :disabled="readOnly"
          :rules="[maxLengthRule(100)]"
          counter="100"
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.trip.destination"
          label="End address"
          :disabled="readOnly"
          :rules="[maxLengthRule(100)]"
          counter="100"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-subheader class="mt-3">{{
      $t("invoicing.transportEventTypes.taxiPlus.taxiPlusCharge")
    }}</v-subheader>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="transportEvent.fare.receiptNumber"
          label="Receipt number"
          :disabled="readOnly"
          :rules="[requiredRule, digitRule, maxLengthRule(8)]"
          counter="8"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.cardNumber"
          label="Card number"
          :disabled="readOnly"
          :rules="[requiredRule, maxLengthRule(20)]"
          counter="20"
          required
        ></v-text-field>
        <v-select
          v-model="transportEvent.fare.vatPercent"
          :items="[0.0, 10.0, 14.0, 24.0, 25.5]"
          :rules="[requiredRule]"
          @input="calculateSums"
          label="Vat percent"
          :disabled="readOnly"
          required
        >
          <template slot="selection" slot-scope="{ item }">
            {{ item }} %
          </template>
          <template slot="item" slot-scope="{ item }"> {{ item }} % </template>
        </v-select>
        <v-text-field
          v-model="transportEvent.fare.cost"
          label="Total amount"
          @change="updateFareCostValue"
          :value="transportEvent.fare.cost | formatPrice"
          :disabled="readOnly"
          type="number"
          :rules="[
            requiredRule,
            decimalRule,
            maxLengthWithoutDecimalSeparatorRule(8),
          ]"
          counter="9"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.discountAmount"
          label="Discount amount"
          :disabled="readOnly"
          type="number"
          :rules="[decimalRule, maxLengthWithoutDecimalSeparatorRule(8)]"
          counter="9"
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.reimbursement"
          label="Total charge"
          disabled
          type="number"
          :rules="[
            requiredRule,
            decimalRule,
            maxLengthWithoutDecimalSeparatorRule(8),
          ]"
          counter="9"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.reimbursementVat"
          label="Total charge vat"
          disabled
          type="number"
          :rules="[decimalRule, maxLengthWithoutDecimalSeparatorRule(8)]"
          counter="9"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="transportEvent.fare.deductible"
          label="Total deductible"
          :disabled="readOnly"
          @input="calculateSums"
          type="number"
          :rules="[
            requiredRule,
            decimalRule,
            maxLengthWithoutDecimalSeparatorRule(6),
          ]"
          counter="7"
          required
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.deductibleVat"
          label="Excess vat"
          disabled
          type="number"
          :rules="[decimalRule, maxLengthWithoutDecimalSeparatorRule(6)]"
          counter="7"
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.waitingCost"
          label="Total waiting"
          @input="calculateSums"
          :disabled="readOnly"
          type="number"
          :rules="[decimalRule, maxLengthWithoutDecimalSeparatorRule(6)]"
          counter="7"
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.waitingVat"
          label="Waiting vat"
          disabled
          type="number"
          :rules="[decimalRule, maxLengthWithoutDecimalSeparatorRule(6)]"
          counter="7"
        ></v-text-field>
        <v-text-field
          v-model="transportEvent.fare.highTariff"
          label="High tariff"
          :disabled="readOnly"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-subheader>{{ $t("invoicing.extraCharges") }} (0-5)</v-subheader>
    <FeeChipGroup
      ref="feeChipGroup"
      :fees="transportEvent.fare.extras"
      :allowedFeeTypes="extraChargeTypes"
      :maxAmount="5"
      :readOnly="readOnly"
      :useLikumaTexts="false"
      @update="updateFees"
    ></FeeChipGroup>
    <v-row>
      <v-textarea
        v-model="transportEvent.comments"
        label="Comments (optional)"
        outlined
        rows="3"
        class="mt-3 mx-2"
      ></v-textarea>
    </v-row>
    <v-row>
          <v-col>
            <v-btn
              color="primary"
              :loading="commentUpdating"
              @click="updateComment">
              {{$t("invoicing.updateComments")}}
            </v-btn>
          </v-col>
        </v-row>
    <v-switch
      v-model="toBeValidated"
      label="Set to be validated"
      :disabled="readOnly"
      hint="Event cannot be modified during the validation process"
      persistent-hint
      class="mt-0"
    ></v-switch>
  </v-tab-item>
      <v-tab-item key="1">
        <v-row class="mt-6">
          <v-col cols="8" md="4">
            <v-subheader class="mt-3">{{
            $t("invoicing.transportEventTypes.receipt.cardRehandleTitle")
          }}</v-subheader>
          <v-btn depressed color="primary" @click="checkCardValidity(transportEvent.fare.cardNumber, transportEvent.fare.paymentTerminalId)">
            Tarkista kortti
          </v-btn>
          </v-col>
          <v-col cols="8" md="4" v-if="cardValidityResponse">
            <v-text-field
                v-model="cardValidityResponse.cardNumber"
                label="Card number"
                readonly
                class="mx-2"
                :append-icon="cardValidityResponse.isValid ? 'mdi-check' : 'mdi-close'"
            ></v-text-field>
            <v-switch
              v-model="authorize"
              label="Tee kortinvarmennus"
            >
            </v-switch>
            <v-radio-group v-if="cardValidityResponse.taxiCardAttributes"
                  v-model="selectedCardAttribute"
                  class="mx-2">
                <v-radio
                  v-for="(attribute, index) in cardValidityResponse.taxiCardAttributes"
                  :key="index"
                  :label="attribute.name + '(Tuotenumero: ' + attribute.externalId + ')'"
                  :value="attribute.externalId"
                  class="mx-2"
              ></v-radio>
              </v-radio-group>
              <v-btn depressed color="warning" @click="rehandleTaxicardPayment(transportEvent.id, selectedCardAttribute, authorize)">
                Uudelleenkäsittele korttitapahtuma
              </v-btn>
        </v-col>
      </v-row>
      </v-tab-item>
    </v-tabs>
  </v-form>
</template>

<script>
import { RepositoryFactory } from "../../data/repositories/repositoryFactory";
import FeeChipGroup from "./FeeChipGroups/FeeChipGroup";
import { FeeTypeMixin, FeeType } from "../../models/FeeTypes";
import InputRuleMixin from "../Inputs/InputRuleMixin";
import { PaymentMethodMixin } from "@/models/PaymentMethods";
import _ from "lodash";

export default {
    components: { FeeChipGroup },
    mixins: [FeeTypeMixin, InputRuleMixin, PaymentMethodMixin],
    props: {
        originalTransportEvent: Object,
        isNew: Boolean,
        readOnly: Boolean
    },
    data() {
        return {
            transportEvent: _.cloneDeep(this.originalTransportEvent),
            valid: true,
            extraChargeTypes: [
                FeeType.AirportFee,
                FeeType.ServiceFee,
                FeeType.AidFee,
                FeeType.HandicapFee,
                FeeType.StretcherFee,
                FeeType.ApproachFee
            ],
            toBeValidated: false,
            commentUpdating: false,
            tripEndedTextFieldType: "datetime-local",
            tripStartedTextFieldType: "datetime-local",
            selectedCardAttribute: null,
            cardValidity: null,
            authorize: true,
            tabs: [
                this.$t("invoicing.transportEventTypes.taxiPlus.taxiPlusTripInfo"),
                this.$t("invoicing.transportEventTypes.receipt.cardTransactionInformation")
            ],
            tab: 0,
            snackbar: false,
            snackbarText: "",
            snackbarColor: ""
        };
    },
    computed: {
        needsRehandling() {
            if (this.transportEvent != null && this.transportEvent.fare != null &&
            this.transportEvent.fare.cardNumber != null &&
                this.transportEvent.fare.cardNumber.length > 0) {
                if (this.transportEvent.validationStatus === 1 &&
            this.transportEvent.salesInvoiceStatus === 1 &&
            this.transportEvent.purchaseInvoiceStatus === 1) {
                    return true;
                } else if (this.transportEvent.validationStatus === 2 &&
            this.transportEvent.salesInvoiceStatus === 1 &&
            this.transportEvent.purchaseInvoiceStatus === 1) {
                    return true;
                } else if (this.transportEvent.validationStatus === 6 &&
            this.transportEvent.salesInvoiceStatus === 2 &&
            this.transportEvent.purchaseInvoiceStatus === 2) {
                    return true;
                }
            }

            return false;
        },
        cardValidityResponse() {
            return this.cardValidity;
        }
    },
    watch: {
        originalTransportEvent(val) {
            this.transportEvent = !val ? {} : _.cloneDeep(val);
            if (!this.transportEvent.fare || !this.transportEvent.fare.extras) {
                this.transportEvent = {
                    fare: {
                        extras: []
                    }
                };
            }
        }
    },
    methods: {
        validate() {
            this.transportEvent.fare.paymentMethod = this.paymentMethods.SocialServiceCard;
            return true;
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        updateFees(fees) {
            this.transportEvent.fare.extras = fees;
            this.$refs.feeChipGroup.closeFeeDialog();
        },
        updateComment() {
            this.commentUpdating = true;
            this.$store.dispatch("transportEventTable/updateTransportEventComments", {
                id: this.transportEvent.id,
                comments: {
                    comments: this.transportEvent.comments,
                    salesInvoiceMessage: this.transportEvent.salesInvoiceMessage,
                    purchaseInvoiceMessage: this.transportEvent.purchaseInvoiceMessage
                }
            })
                .finally(() => {
                    this.commentUpdating = false;
                });
        },
        calculateSums() {
            let fare = this.transportEvent.fare;
            fare.reimbursement = (fare.cost - fare.deductible).toFixed(2);
            fare.reimbursementVat = this.calculateVat(
                fare.reimbursement,
                fare.vatPercent
            );
            fare.deductibleVat = this.calculateVat(fare.deductible, fare.vatPercent);
            fare.waitingVat = this.calculateVat(fare.waitingCost, fare.vatPercent);
        },
        calculateVat(sumIncludingVat, vatPercent) {
            let vat = sumIncludingVat - sumIncludingVat / (vatPercent / 100.0 + 1);
            return vat.toFixed(2);
        },
        setToBeCorrected() {
            this.$emit("correctionRequested");
        },
        updateFareCostValue(updateFareCostEvent) {
            this.transportEvent.fare.cost = updateFareCostEvent;
            this.calculateSums();
        },
        dblClickTripEndedTypeChange() {
            if (this.tripEndedTextFieldType === "datetime-local") {
                this.tripEndedTextFieldType = "text";
            } else {
                this.tripEndedTextFieldType = "datetime-local";
            }
        },
        dblClickTripStartedTypeChange() {
            if (this.tripStartedTextFieldType === "datetime-local") {
                this.tripStartedTextFieldType = "text";
            } else {
                this.tripStartedTextFieldType = "datetime-local";
            }
        },
        checkCardValidity(cardNumber, serviceNumber) {
            RepositoryFactory.get("invoicing").getCardValidity(cardNumber, serviceNumber)
                .then(response => {
                    this.cardValidity = response.data.item;
                })
                .catch(error => {
                    this.snackbarText = error;
                    this.snackbarColor = "error";
                    this.snackbar = true;
                });
        },
        rehandleTaxicardPayment(id, externalId, authorize) {
            if (!window.confirm("Oletko varma, että haluat käsitellä tapahtuman uudelleen? Tapahtuma ilmestyy Semel IP hetken kulttua.")) {
                return;
            }
            RepositoryFactory.get("invoicing").rehandleTaxicardPaymentToSemelCF(id, externalId, authorize)
                .then(response => {
                    this.snackbarText = "Uudelleenkäsittely onnistui";
                    this.snackbarColor = "success";
                    this.snackbar = true;
                })
                .catch(error => {
                    this.snackbarText = error;
                    this.snackbarColor = "error";
                    this.snackbar = true;
                });
        }
    }
};
</script>

<style>
</style>
